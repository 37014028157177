<template>
	<div class="pro-detail-body">
		<div class="content" v-if="info.prtType">
			<div class="icon" />
			<span class="span">
				<span v-if="info.prtType">类别：{{ info.prtType }}</span>
				<span v-else>类别：暂无</span>
			</span>
		</div>
		<div class="content" v-if="info.prtCode">
			<div class="icon" />
			<span class="span">
				<span v-if="info.prtCode">货号：{{ info.prtCode }}</span>
				<span v-else>货号：暂无</span>
			</span>
		</div>

		<div class="content" v-if="info.prtInch">
			<div class="icon" />
			<span class="span">
				<span v-if="info.prtInch">寸数：{{ info.prtInch + "寸" }}</span>
				<span v-else>寸数：暂无</span>
			</span>
		</div>
		<div class="content" v-if="info.prtHairNum">
			<div class="icon" />
			<span class="span">
				<span v-if="info.prtHairNum">发数：{{ info.prtHairNum + "发" }}</span>
				<span v-else>发数：暂无</span>
			</span>
		</div>
		<div class="content">
			<div class="icon" />
			<span class="span">
				体积：
				<span>{{ info.prtLong?info.prtLong:0 }}*{{ info.prtWide? info.prtWide: 0}}*{{ info.prtHigh ?info.prtHigh : 0}}</span>
				<span
					class="text-theme-color">={{ (((info.prtLong? parseFloat(info.prtLong) :0) * (info.prtWide?parseFloat(info.prtWide):0)  * (info.prtHigh ? parseFloat(info.prtHigh) : 0))/1000000).toFixed(2) }}
					m³ </span>
			</span>
		</div>

		<div class="content" v-if="info.prtSpecs">
			<div class="icon" />
			<span class="span" v-if="info.prtSpecs">
				规格：
				<span class="text-theme-color">{{ info.prtSpecs }}</span>
			</span>
			<span v-else>规格：暂无</span>
		</div>

		<div class="content">
			<div class="icon" />
			<span class="span">
				含量：
				<span class="text-theme-color">{{ info.prtContent?info.prtContent:'0/0' }}</span>
			</span>
		</div>

		<div class="content">
			<div class="icon" />
			<span class="span">
				毛重：
				<span class="text-theme-color">{{ info.prtWeight?info.prtWeight:'0.00' }}KG</span>
			</span>
		</div>

		<div class="content" v-if="info.prtEffect">
			<div class="icon" />
			<span class="span" v-if="info.prtEffect">
				效果：
				<span class="text-theme-color">{{ info.prtEffect }}</span>
			</span>
			<span v-else>效果：暂无</span>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['info']
	}
</script>

<style lang="less">
	.pro-detail-body {
		width: 100%;
		padding-left: 30px;
		padding-top: 16px;
		display: flex;
		flex-direction: column;
		background: #ffffff;

		.margin-left {}

		.price {
			font-size: 15px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #ff1448;
			margin: 14px 0 18px;
		}

		.content {
			display: flex;
			align-items: center;
			margin-bottom: 9px;

			.icon {
				width: 6px;
				/*no*/
				height: 6px;
				/*no*/
				background: var(--theme-color);
				border-radius: 50%;
			}

			.span {
				font-size: 13px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #323232;
				margin-left: 8px;
			}
		}
	}
</style>
