<template>
	<div>
		<van-popup v-model="showPoster" class="show-poster-content">
			<div id="html2canvasRef" ref="html2canvasRef" class="content-img">
				<div class="imgs">
					<img class="home-logo" :src="posterLogo" alt="" />
					<img class="info-img" :src="posterContent" />
				</div>
				<div class="content-title">
					<div class="title">{{ info.prtName }}</div>
					<div style="display: flex; justify-content: space-between">
						<ul>
							<div class="content">
								<div class="flex-center">
									<div class="icon" />
								</div>
								<span class="span">
									<span v-if="info.prtType">类别：{{ info.prtType }}</span>
									<span v-else>类别：暂无</span>
								</span>
							</div>
							<div class="content">
								<div class="flex-center">
									<div class="icon" />
								</div>
								<span class="span">
									体积：
									<span>{{ info.prtLong?info.prtLong:0 }}*{{ info.prtWide? info.prtWide: 0}}*{{ info.prtHigh ?info.prtHigh : 0}}</span>
									<span
										class="text-theme-color">={{ ((info.prtLong?info.prtLong:0 * info.prtWide?info.prtWide:0 * info.prtHigh?info.prtHigh:0)/1000000).toFixed(2) }}
										m³ </span>
								</span>
							</div>
							<div class="content">
								<div class="flex-center">
									<div class="icon" />
								</div>

								<span class="span">
									含量：
									<span class="text-theme-color">{{ info.prtContent?info.prtContent:'0/0' }}</span>
								</span>
							</div>

							<div class="content">
								<div class="flex-center">
									<div class="icon" />
								</div>
								<span class="span">
									毛重：
									<span class="text-theme-color">{{ info.prtWeight?info.prtWeight:'0.00' }}KG</span>
								</span>
							</div>
						</ul>
						<div>
							<img class="qrCode" :src="posterQRImg" />
							<div class="flex-center text-blue text-sm">
								(扫一扫看效果)
							</div>
						</div>
					</div>
				</div>
				<div class="footer">长按图片保存到相册</div>
			</div>
			<img src="@/assets/img/proDetails/whiteBG.png" class="top-img-bg" />
			<img v-show="genImg" :src="genImg" class="top-img" />
		</van-popup>
	</div>
</template>

<script>
	import html2canvas from "html2canvas";
	import {
		VueCanvasPoster
	} from "vue-canvas-poster";
	import QRCode from "qrcodejs2";
	import {
		Toast
	} from 'vant';
	export default {
		components: {
			QRCode,
			VueCanvasPoster,
		},
		data() {
			return {
				needUpdate: true,
				showPoster: false,
				painting: "",
				genImg: "",
				posterLogo: '',
				posterContent: ''
			};
		},
		props: {
			info: {
				type: [Object],
				default: {},
			},
			posterQR: {
				type: String,
				default: "",
			}
		},
		computed: {
			posterQRImg() {
				this.needUpdate = true;
				return this.posterQR;
			},
			posterContentImg() {
				this.needUpdate = true;
				return this.posterContent;
			},
			posterLogoImg() {
				this.needUpdate = true;
				return this.posterLogo;
			},
		},
		updated() {

		},
		methods: {

			async saveImage() {
				// if (!this.needUpdate) {
				//   return;
				// }
				Toast.loading({
					message: '正在生成海报请稍后...',
					forbidClick: true
				});
				let self = this;
				
				await this.$nextTick(() =>{
					let canvasID = this.$refs.html2canvasRef;
					 html2canvas(canvasID)
						.then((canvas) => {
							self.genImg = canvas.toDataURL("image/png");
							self.needUpdate = false;
							Toast.clear();
						})
						.catch((e) => {});
				})
				
			},
			async show(logo, contentImg,imgUrl) {
				let _this = this;
				this.posterLogo = logo;
				this.posterContent = '';
				this.$nextTick(() =>{
					var canvas = document.createElement("canvas"),
						ctx = canvas.getContext("2d"),
						img = new Image();
					img.crossOrigin = "";
					img.src = imgUrl;
					img.onload = function() {
						canvas.height = img.height;
						canvas.width = img.width;
						ctx.drawImage(img, 0, 0);
						var  dataURL = canvas.toDataURL("image/png");
						canvas = null;
						_this.posterContent = dataURL;
						_this.showPoster = true;
						_this.genImg = '';
						_this.saveImage();
					};
				})
			},
		},
	};
</script>

<style lang="less" scoped>
	.top-img-bg {
		position: absolute;
		top: 0px;
		left: 0;
		width: 317px;
		height: 534px;
		z-index: 100;
	}

	.text-blue {
		color: #1989FA;
	}

	.text-sm {
		font-size: 12px;
	}

	.top-img {
		position: absolute;
		top: 0px;
		left: 0;
		width: 317px;
		height: 508px;
		z-index: 999;
		border-radius: 20px;
	}

	::v-deep .canvas {
		top: 10px;
		width: 310px !important;
	}

	ul {
		list-style: none;
		counter-reset: count;
		padding-top: 8px;
	}

	li {
		color: #969696;
		font-size: 11px;
	}

	li::before {
		content: " • ";
		color: #1989fa;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 18px;
	}

	.show-poster-content {

		border-radius: 20px;

		.content-img {
			width: 317px;
			height: 508px;
			background-color: #ffffff;
			border-radius: 20px;
			position: relative;

			.imgs {
				padding: 26px 26px 0 26px;

				.home-logo {
					height: 34px;
				}

				.info-img {
					height: 265px;
					width: 265px;
				}
			}

			.content-title {
				margin: 0 20px 20px 20px;

				.title {
					font-size: 18px;
					font-weight: bold;
				}

				.content {
					display: flex;
					align-items: center;
					margin-bottom: 9px;

					.icon {
						width: 8px;
						/*no*/
						height: 8px;
						/*no*/
						background: var(--theme-color);
						border-radius: 50%;
					}

					.span {
						font-size: 13px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #323232;
						margin-left: 8px;
					}
				}
			}

			.qrCode {
				width: 85px;
				height: 85px;
			}

			.footer {
				line-height: 48px;
				width: 100%;
				bottom: 0;
				left: 0;
				background: linear-gradient(-90deg, #3f48ff 0%, #26baff 100%);
				font-size: 18px;
				font-weight: 500;
				color: #ffffff;
				text-align: center;
				position: absolute;
				// border-radius: 0 0 20px 20px;
			}
		}
	}
</style>
