<template>
	<div class="product-container">
		<div class="card" @click="goProDetail">
			<div class="item-img" :style="{backgroundImage: 'url(' + (info.typeBg ? info.typeBg : '') + ')'}">
				<img v-if="prtPictureList[0]" :src="prtPictureList[0].filePath " alt="" />
				<img v-else :src="emptyImg" />
			</div>
			<div>
				<div class="flex" style="padding: 10px 8px 0 8px;">
					<div class="flex-center" style="width: 10px;">
						<div class="line"></div>
					</div>
					<div class="title">{{ info.prtName }}</div>
				</div>

				<ul>
					<li>
						<div class="flex-center" style="width: 10px;">
							<div class="circle"></div>
						</div>
						<div class="text-tag text-cut">
							<span>体积：{{ info.prtLong?info.prtLong:0 }}*{{ info.prtWide?info.prtWide:0 }}*{{ info.prtHigh?info.prtHigh:0 }}cm</span>
						</div>
					</li>
					<li>
						<div class="flex-center" style="width: 10px;">
							<div class="circle"></div>
						</div>
						<div class="text-tag">含量：{{ info.prtContent || '暂无' }}</div>
					</li>
				</ul>
				<div class="borwse">
					浏览量:
					<span style="color: #36C9FF;">{{ info.clickCount }}次</span>
				</div>
			</div>
		</div>
		<div class="item-btn">
			<!-- :style="'background-image:url('+productIcon.cart+')'" -->
			<div class="btn-left"  @click="addCart">
				<img :src="productIcon.cart"/>
			</div>
			
			<!-- :style="'background-image:url('+ (video(info) ? productIcon.video : productIcon.n_video)+')'" -->
			<div class="btn-right"
				@click="handleVideoPlay">
				<img :src="(video(info) ? productIcon.video : productIcon.n_video)"/>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		setPageTitle
	} from '@/utils';
	import {
		getFileList,
		clickPrtAddOne,
		addToCart
	} from '@/api';
	import {
		Toast
	} from 'vant';
	import _ from 'lodash';
	import imgs from '@/assets/img/stoplist/empty.png';
	import b_cart from '@/assets/img-v3/new-blue-detail/cart.png';
	import b_has_video from '@/assets/img-v3/new-blue-detail/has-video.png';
	import r_cart from '@/assets/img-v3/new-red-detail/cart.png';
	import r_has_video from '@/assets/img-v3/new-red-detail/has-video.png';
	import no_video from '@/assets/img-v3/new-blue-detail/no-video.png';
	export default {
		name: 'ProductList',
		props: {
			disabled: {
				type: Boolean,
				default: true
			},
			// eslint-disable-next-line vue/require-default-prop
			info: {
				type: Object
			},
		},
		data() {
			return {
				emptyImg: imgs,
				// 产品视频列表--------------------------------------------
				prtVideoList: [],
				bgImg: 'background-image: url(../../assets/img/nav/back.png);',
				productIcon: {
					cart: b_cart,
					video: b_has_video,
					n_video: no_video
				},
				hasVideo: true
			};
		},
		computed: {
			// 产品图片列表--------------------------------------------
			prtPictureList() {
				const picList = this.info?.picList || [];
				return picList.filter(o => o.typeName == 'prtPicture');
			},
			// 视频图片
			videoPictureList() {
				const picList = this.info?.picList || [];
				return picList.filter(o => o.typeName == 'videoPicture');
			},
			// 产品详情列表--------------------------------------------
			prtDetailList() {
				const picList = this.info?.picList || [];
				return picList.filter(o => o.typeName == 'prtInfo');
			},
			// 生成视频左下角信息
			getVideoInfo_demo() {
				return _.reduce(
					this.info,
					(result, value, key) => {
						if (value) {
							switch (key) {
								case 'prtName':
									if (this.info.isName == 0) {
										result.push({
											label: '名称',
											value
										});
									}
									break;
								case 'prtCode':
									if (this.info.isCode == 0) {
										result.push({
											label: '货号',
											value
										});
									}
									break;
								case 'prtType':
									if (this.info.isType == 0) {
										result.push({
											label: '分类',
											value
										});
									}
									break;
								case 'prtLong':
									if (this.info.isChiCun == 0) {
										result.push({
											label: '尺寸',
											value: `${value}cm*${this.info.prtWide}cm*${this.info.prtHigh}cm`
										});
									}
									break;
								case 'prtSpecs':
									if (this.info.isSpecs == 0) {
										result.push({
											label: '规格',
											value
										});
									}
									break;
								case 'prtContent':
									if (this.info.isContent == 0) {
										result.push({
											label: '含量',
											value
										});
									}
									break;
								case 'prtHairNum':
									if (this.info.isHairNum == 0) {
										result.push({
											label: '发数',
											value: value + '发'
										});
									}
									break;
								case 'prtInch':
									if (this.info.isInch == 0) {
										result.push({
											label: '寸数',
											value: value + '寸'
										});
									}
									break;
								case 'prtWeight':
									if (this.info.isWeight == 0) {
										result.push({
											label: '重量',
											value: value + 'kg'
										});
									}
									break;
								case 'prtEffect':
									if (this.info.isEffect == 0) {
										result.push({
											label: '效果',
											value
										});
									}
									break;
							}
						}
						return result;
					},
					[]
				);
			},
			getVideoInfo() {
				let result = [];
				if (this.info.isName == 0) {
					result.push({
						label: '名称',
						value: this.info.prtName,
						state: this.info.isName
					});
				}
				if (this.info.isType == 0) {
					result.push({
						label: '类别',
						value: this.info.prtType,
						state: this.info.isType
					});
				}
				if (this.info.isCode == 0) {
					result.push({
						label: '货号',
						value: this.info.prtCode,
						state: this.info.isCode
					});
				}
				if (this.info.isChiCun == 0) {
					result.push({
						label: '体积',
						value: `${this.info.prtLong}cm*${this.info.prtWide}cm*${this.info.prtHigh}cm`,
						state: this.info.isChiCun
					});
				}
				if (this.info.isSpecs == 0) {
					result.push({
						label: '规格',
						value: this.info.prtSpecs,
						state: this.info.isSpecs
					});
				}
				if (this.info.isContent == 0) {
					result.push({
						label: '含量',
						value: this.info.prtContent,
						state: this.info.isContent
					});
				}
				if (this.info.isHairNum == 0) {
					result.push({
						label: '发数',
						value: this.info.prtHairNum + '发',
						state: this.info.isHairNum
					});
				}
				if (this.info.isInch == 0) {
					result.push({
						label: '寸数',
						value: this.info.prtInch + '寸',
						state: this.info.isInch
					});
				}
				if (this.info.isWeight == 0) {
					result.push({
						label: '重量',
						value: this.info.prtWeight + 'kg',
						state: this.info.isWeight
					});
				}
				if (this.info.isEffect == 0) {
					result.push({
						label: '效果',
						value: this.info.prtEffect,
						state: this.info.isEffect
					});
				}
				return result;
			}
		},
		created() {
			// this.getFileList()
		},
		methods: {
			video(info) {
				if (info?.picList?.length > 0) {
					const picList = info.picList || [];
					const video = picList.filter(item => item.typeName == 'prtVideo');
					this.hasVideo = true
					if(video.length > 0){
						return true
					}else{
						if(info?.prtVideoUrl){
							this.hasVideo = true
							return true
						}else{
							return false
						}
					}
				} 
				if(info?.prtVideoUrl){
					this.hasVideo = true
					return true
				} else {
					this.hasVideo = false
					return false
				}
			},
			addCart() {
				let userId =this.$store.state.userInfo? this.$store.state.userInfo.uuid:''
				let param = {
					num: 1,
					productId: this.info.uuid,
					shopUuid: this.$store.state.shopUuid,
					userId: userId // 分类名
				};
				addToCart(param).then(res => {
					if (res.state == 100) {
						Toast.success('成功加入购物车');
					}
				});
			},
			async handleVideoPlay() {
				if (!this.hasVideo) return;
				this.prtVideoList = await this.getFileList();
				// 增加视频点击量
				clickPrtAddOne({
					uuid: this.info.uuid
				});
				const videoArr = this.prtVideoList.map((item, index) => {
					const oneVideo = {
						id: index,
						bgImg: this.info.isPic == 0 ? this.videoPictureList[0]?.filePath ?? '' : '',
						isLoad: index == 0,
						sources: item.filePath || '',
						infoArr: this.getVideoInfo,
						uuid: this.info.uuid,
						isLike: this.info.isLike,
						prtVideoUrl:this.info?.prtVideoUrl
					};
					return oneVideo;
				});
				localStorage.setItem('videoArr', JSON.stringify(videoArr));
				this.$router.push({
					name: 'VideoPlay',
					query: {
						uuid: this.info.uuid,
						type: 'noClose',
						title: this.info.prtName,
						shopUuid: this.$store.state.shopUuid
					}
				});
			},
			goProDetail() {
				let data = {
					uuid: this.info.uuid,
					title: this.info.prtName,
					typeBg: this.info.typeBg,
					shopUuid: this.$store.state.shopUuid
				}
				this.$emit('getInfo', data)
				// this.$router.push({
				// 	name: 'ProductDetails',
				// 	query: { uuid: this.info.uuid, title: this.info.prtName,typeBg:this.info.typeBg,shopUuid:this.$store.state.shopUuid }
				// });
			},

			// 获取视频
			getFileList() {
				const params = {
					page: 0,
					pageSize: 0,
					typeName: 'prtVideo',
					parentUuid: this.info.uuid
				};
				return getFileList(params).then(res => {
					if (res.state == 100) {
						return res.items;
						// this.prtVideoList = res.items
					}
				});
			},
			handleDelete() {
				this.$emit('handleDelete', this.info);
			}
		}
	};
</script>

<style lang="less" scoped>
	.product-container {
		width: 46.4vw;
		// width: 172px;
	}

	.text-cut {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	ul {
		list-style: none;
		counter-reset: count;
		padding-top: 6px;
	}

	li {
		color: #969696;
		font-size: 11px;
		padding-left: 8px;
		display: flex;
		line-height: 18px;
	}

	li::before {
		content: '';
		color: #36C9FF;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 20px;
	}

	.flex-center {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.line {
		width: 3px;
		height: 14px;
		background: #36C9FF;
		border-radius: 1px;
	}

	.circle {
		height: 6px;
		width: 6px;
		border-radius: 50% !important;
		background-color: #36C9FF;
		margin-top: -1px;
	}

	.card {
		width: 100%;
		background: #ffffff;
		// box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.1);
		box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
		border-radius: 5px;
		// margin-top: 10px;
		padding-bottom: 10px;

		.item-img {
			// width: 47%;
			// min-width: 150px;
			height: 191px;
			top: 0px;
			left: 0px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-size: 100% 100%;
			border-radius: 5px 5px 0 0;

			img {
				width: 100%;
				height: 100%;
				border-radius: 5px 5px 0 0;
			}
		}

		.title {
			font-size: 13px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #000000;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			padding-left: 5px;
		}

		.text-tag {
			padding-left: 5px;
			font-size: 11px;
			width: 80%;
		}

		.borwse {
			text-align: right;
			font-size: 10px;
			padding-right: 12px;
			color: #969696;
			height: 11px;
			padding-top: 4px;
		}
	}

	.item-btn {
		width: 100%;
		height: 46px;
		line-height: 46px;
		/*box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.2);*/
		// border-radius: 16px;
		overflow: hidden;
		position: relative;
		margin-top: 7px;

		.btn-left,
		.btn-right {
			/*flex: 1;*/
			display: flex;
			// justify-content: center;
			align-items: center;
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #ffffff;
			line-height: 46px;

			img {
				width: 100%;
			}

			span {
				height: 17px;
				line-height: 18px;
			}
		}


		.btn-left {
			position: absolute;
			width: 29vw;
			padding-right: 4px;
			left: 0px;
			top: 0;
			z-index: 99;
			// background: url('../../assets/img/home/1.png') no-repeat;
			background-size: 100% 100%;
			//  background: linear-gradient(90deg, #8be73f 0%, #4d5af1 0%, #636fff 100%);
		}

		.btn-left:active {

			transform: scale(0.98);
			-ms-transform: scale(0.98);
			/* IE 9 */
			-moz-transform: scale(0.98);
			/* Firefox */
			-webkit-transform: scale(0.98);
			/* Safari 和 Chrome */
			-o-transform: scale(0.98);
			/* Opera */
			box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
		}

		.btn-right {
			position: absolute;
			width: 29vw;
			padding-left: 15px;
			top: 0;
			z-index: 98;
			right: 0px;
			// background: url('../../assets/img/home/2.png') no-repeat;
			background-size: 100% 100%;
			// background: linear-gradient(90deg, #b63cfb 0%, #ff3cf8 100%);
		}

		.no-video {
			background: url('../../assets/img/home/3.png') no-repeat;
			background-size: 100% 100%;
			// background: linear-gradient(90deg, #838383 0%, #c3c3c3 100%);
		}
	}

	// }
	.product-list {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.van-swipe-cell {
			width: 100%;
			margin-bottom: 7px;

			/deep/ .van-swipe-cell__wrapper {
				display: flex;
				justify-content: center;

				.product-item {
					width: 361px;
					height: 150px;
					background: #ffffff;
					box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.03);
					border-radius: 10px;
					padding: 10px;
					display: flex;
					flex-direction: row;
					align-items: center;

					.item-img {
						width: 130px;
						height: 130px;

						img {
							width: 100%;
							height: 100%;
							border-radius: 5px;
						}
					}

					.item-content {
						width: 191px;
						height: 116.5px;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						margin-left: 20px;
						font-family: PingFang SC;
						font-weight: 500;
						position: relative;

						.shou-cang {
							position: absolute;
							top: 0;
							right: 0;

							&>img {
								width: 14px;
								height: 15px;
							}
						}

						&>span {
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}

						.prt-title {
							width: 170px;
							font-size: 15px;
							color: #000000;
							margin: 0 0 8px;
							font-weight: 400;
						}

						.prt-inch,
						.prt-content {
							font-size: 11px;
							color: #989898;
							font-family: PingFang SC;
							font-weight: 400;
						}

						.prt-content {
							margin: 4px 0 5px;
						}

						.prt-price {
							font-size: 12px;
							color: #ff1448;
							margin-bottom: 8px;
						}
					}
				}

				.delete-button {
					width: 65px;
					height: 100%;
					background: #ff1448;
					box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.03);
					border-top-left-radius: 10px;
					border-bottom-left-radius: 10px;
				}
			}
		}
	}
</style>
