<template>
	<div class="product-detail">
		<div class="top-nav-bar">
			<TopNavBar />
		</div>
		<div class="flex-center" style="background: #ffffff">
			<div class="pro-detail-top">
				<div style="width: 100%;100%;">
					<div class="swipe-container">
						<van-swipe class="my-swipe" :show-indicators="false" indicator-color="white">
							<van-swipe-item v-for="(item, index) in pictureList" :key="item.uuid">
								<div class="swipe-item-box" :style="{
                    backgroundImage:
                      'url(' + (index == 0 && typeBg ? typeBg : '') + ')',
                  }">
									<img :src="item.filePath" alt="" />
								</div>
							</van-swipe-item>
						</van-swipe>
					</div>
					<img v-if="hasVideo" class="video-play-icon" src="~@/assets/img-v3/new-playVideo/play.png" alt=""
						@click="goVideoPlay" />
				</div>

				<div v-if="historyList.length != 0 && userInfo.uuid == 'KtKPS245878199'" class="right-btn"
					@click="showBrowseModal = true">
					<img :src="historyList[0].headimgurl" @error="errorImg" height="22px" width="22px" />
					<div style="padding-left: 5px; font-size: 13px">浏览人名</div>
				</div>
			</div>
		</div>
		<div class="flex-center margin-top">
			<div class="flex">
				<div class="picture-btn">
					<img :src="detailIcon.pic" style="width: 65px;" />
				</div>
				<div v-if="hasVideo" class="video-btn">
					<img :src="detailIcon.video" style="width: 65px;" />
				</div>
			</div>
		</div>

		<div class="pro-title">
			<div class="left-line"></div>
			<span class="title">{{ info.prtName }}</span>
			<img :src="detailIcon.qrCode" @click="onClickIcon(3)" width="21px" height="21px" />
		</div>

		<Tips :info="info"></Tips>
		<div style="text-align: right; padding-right: 20px; background: #ffffff">
			￥：
			<span class="text-theme-color"
				v-if="$store.state.userInfo.lv != 1 && $store.state.userInfo.lv != 99">{{info.prtPrice?info.prtPrice:"价格面议"}}</span>
			<span v-if="$store.state.userInfo.lv == 1" class="text-theme-color" @click="apply">申请授权</span>
			<span class="text-theme-color"
				v-if="$store.state.userInfo.lv == 99">{{info.prtPrice?info.prtPrice:"价格面议"}}</span>


		</div>
		<div class="footer-like-list">
			<div style="display: flex; padding-right: 30px">
				<img :src="detailIcon.d_qrCode" />
				<div class="num">{{ info.clickCount }}次</div>
			</div>
			<div style="display: flex" @click="handleFn('isLike', info.isLike)">
				<img :src="detailIcon.like" />
				<div class="num">{{ info.collCount }}次</div>
			</div>
		</div>
		<div class="qy-qrcode" v-if="wxQrCode">
			<img :src="wxQrCode" />
			<div style="display: flex; align-items: center; padding-left: 20px">
				<div>
					<div class="qrcode-title">{{ shopName }}</div>
					<div class="qrcode-mark">关注我们了解更多企业信息</div>
				</div>
			</div>
		</div>

		<!-- 产品详情 -->
		<div class="detail-pictures">
			<div class="title">
				<img :src="detailIcon.shock" alt="" />
				<span>产品详情</span>
				<span style="font-size: 13px; color: #c8c8c8; font-weight: 400">/XIANGQING</span>
			</div>
			<div class="" v-if="detailPic.length">
				<van-image v-for="(item, i) in detailPic" :key="i" fit="cover" :src="item.filePath" alt=""
					@click="imagePreview(i, detailPic)" />
			</div>
		</div>
		<!-- 猜你喜欢 -->
		<RecommendList @getInfos="getInfo" />
		<!-- 生产海报 -->
		<poster ref="poster" :info="info" :posterQR="posterQR"></poster>
		<!-- 弹出浏览记录 -->
		<van-popup v-model="showBrowseModal" position="bottom" style="border-radius: 10px 10px 0 0"
			:style="{ height: '70%' }">
			<div class="browseModal">
				<div class="header">浏览记录</div>
				<div class="content">
					<div class="list" v-for="(item, i) in historyList" :key="i">
						<div style="display: flex">
							<img :src="item.headimgurl" @error="errorImg" />
							<span>{{ item.nickname }}</span>
						</div>
						<div style="color: #919394">{{ item.optionDate }}</div>
					</div>
				</div>
				<div class="foot" @click="showBrowseModal = false">关闭</div>
			</div>
		</van-popup>
		<!-- 底部菜单 -->

		<div class="footer">
			<div @click="onClickIcon(1)">
				<div class="flex-center">
					<img src="~@/assets/img-v3/new-blue-detail/home.png" />
				</div>
				<div class="text-center">首页</div>
			</div>
			<div @click="handleFn('isColl', info.isColl)">
				<div class="flex-center">
					<img :src="info.isColl == 1 ? collectActive : collect" />
				</div>
				<div class="text-center">
					{{ info.isColl == 1 ? "已收藏" : "收藏" }}
				</div>
			</div>
			<div @click="onClickIcon(3)">
				<div class="flex-center">
					<img src="~@/assets/img-v3/new-blue-detail/poster.png" />
				</div>
				<div class="text-center">生成海报</div>
			</div>
			<div class="flex-center">
				<div class="btn" @click="onClickIcon(4)">加入购物车</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		setPageTitle
	} from "@/utils";
	import {
		ImagePreview,
		Toast
	} from "vant";
	import TopNavBar from "./components/TopNavBar.vue";
	import {
		getProOne,
		getFileList,
		reCollection,
		collection,
		collections,
		clickPrtAddOne,
		addToCart,
		productQRCode,
		productQRCode2,
		browsingHistory,
		getShopOne
	} from "@/api/index.js";
	import RecommendList from "./components/RecommendList.vue";
	import Poster from "./components/Poster.vue";
	import Swipe from "./components/Swipe.vue";
	import Tips from './components/tips.vue';

	import headerImg from "@/assets/img/proDetails/header.png";
	import collect from "@/assets/img-v3/new-blue-detail/collect.png";
	import collectActive from "@/assets/img-v3/new-blue-detail/collect-active.png";

	import b_shock from '@/assets/img-v3/new-blue-detail/shock.png'
	import b_qr_code from '@/assets/img-v3/new-blue-detail/qrcode-icon.png'
	import b_d_qr_code from '@/assets/img-v3/new-blue-detail/d-qr-code.png'
	import b_like from '@/assets/img-v3/new-blue-detail/like.png';
	import b_pic from '@/assets/img-v3/new-blue-detail/pic.png';
	import b_video from '@/assets/img-v3/new-blue-detail/video.png';

	import r_shock from '@/assets/img-v3/new-red-detail/shock.png'
	import r_qr_code from '@/assets/img-v3/new-red-detail/qrcode-icon.png'
	import r_d_qr_code from '@/assets/img-v3/new-red-detail/d-qr-code.png'
	import r_like from '@/assets/img-v3/new-red-detail/like.png';
	import r_pic from '@/assets/img-v3/new-red-detail/pic.png';
	import r_video from '@/assets/img-v3/new-red-detail/video.png';


	import {
		wxConfig
	} from "@/api/wxApi";
	export default {
		name: "ProductDetails",
		components: {
			TopNavBar,
			RecommendList,
			Poster,
			Swipe,
			Tips
		},
		data() {
			return {
				detailIcon: {
					qrCode: b_qr_code,
					shock: b_shock,
					d_qrCode: b_d_qr_code,
					like: b_like,
					pic: b_pic,
					video: b_video
				},
				collect: collect,
				collectActive: collectActive,
				uuid: "",
				info: {},
				pictureList: [],
				likColl: 0,
				detailPic: [],
				prtVideoList: [],
				videoPictureList: [],
				title: "",
				qrCode: "",
				showBrowseModal: false,
				historyList: "",
				headerImg: headerImg,
				wxQrCode: "",
				typeBg: "",
				posterQR: null,
				posterContent: null,
				posterLogo: null,

			};
		},

		computed: {
			randomNum() {
				return parseInt(Math.random() * 10);
			},
			userInfo() {
				return this.$store.state.userInfo;
			},
			shopName() {
				return this.$store.state.shopInfo.list.shopName;
			},
			baseUrl() {
				return this.$store.state.baseUrl;
			},
			hasVideo() {
				const picList = this.info?.picList || [];
				const video = picList.filter((item) => item.typeName == "prtVideo");

				const videoUrl = this.info?.prtVideoUrl
				if (video.length > 0 || videoUrl) {
					return true
				} else {
					return false
				}
			},
			// 生成视频左下角信息
			getVideoInfo_demo() {
				let result = [];
				result.push({
					label: "名称",
					value: this.info.prtName ? this.info.prtName : "暂无",
					state: this.info.isName,
				});
				result.push({
					label: "类别",
					value: this.info.prtType ? this.info.prtType : "暂无",
					state: this.info.isType,
				});
				result.push({
					label: "体积",
					value: `${this.info.prtLong}*${this.info.prtWide}*${this.info.prtHigh}cm`,
					state: this.info.isChiCun,
				});
				result.push({
					label: "含量",
					value: this.info.prtContent ? this.info.prtContent : "暂无",
					state: this.info.isContent,
				});
				return result;
			},
			// 生成视频左下角信息
			getVideoInfo() {
				let result = [];
				if (this.info.isName == 1) {
					result.push({
						label: "名称",
						value: this.info.prtName,
						state: this.info.isName,
					});
				}
				if (this.info.isType == 1) {
					result.push({
						label: "类别",
						value: this.info.prtType,
						state: this.info.isType,
					});
				}
				if (this.info.isCode == 1) {
					result.push({
						label: "货号",
						value: this.info.prtCode,
						state: this.info.isCode,
					});
				}
				if (this.info.isChiCun == 1) {
					result.push({
						label: "体积",
						value: `${this.info.prtLong}cm*${this.info.prtWide}cm*${this.info.prtHigh}cm`,
						state: this.info.isChiCun,
					});
				}
				if (this.info.isSpecs == 1) {
					result.push({
						label: "规格",
						value: this.info.prtSpecs,
						state: this.info.isSpecs,
					});
				}
				if (this.info.isContent == 1) {
					result.push({
						label: "含量",
						value: this.info.prtContent,
						state: this.info.isContent,
					});
				}
				if (this.info.isHairNum == 1) {
					result.push({
						label: "发数",
						value: this.info.prtHairNum + "发",
						state: this.info.isHairNum,
					});
				}
				if (this.info.isInch == 1) {
					result.push({
						label: "寸数",
						value: this.info.prtInch + "寸",
						state: this.info.isInch,
					});
				}
				if (this.info.isWeight == 1) {
					result.push({
						label: "重量",
						value: this.info.prtWeight + "KG",
						state: this.info.isWeight,
					});
				}
				if (this.info.isEffect == 1) {
					result.push({
						label: "效果",
						value: this.info.prtEffect,
						state: this.info.isEffect,
					});
				}
				return result;
			},
		},
		watch: {
			$route() {
				const {
					uuid
				} = this.$route.query;
				this.uuid = uuid;
				this.getProOne();
				this.qrCode = this.baseUrl + productQRCode() + this.uuid;
			},
		},

		async mounted() {
			const query = this.$route.query;

			// this.title = query?.title;
			// this.$route.meta.title = this.title;
			const {
				uuid
			} = this.$route.query;
			this.uuid = uuid;
			this.typeBg = query?.typeBg;
			if (query.shopUuid) {
				this.$store.commit('SET_SHOP_UUID', query.shopUuid)
			}
			this.getProOne();
			this.qrCode = this.baseUrl + productQRCode2() + this.uuid;
			let self = this;

		},
		beforeCreated() {
			this.pictureList = [];
		},
		created() {},

		deactivated() {
			this.pictureList = [];
			const shopInfo = this.$store.state.shopInfo;
			this.$destroy(this);
		},

		methods: {
			async getShopOne() {
				await getShopOne({
					uuid: this.$store.state.shopUuid
				}).then((res) => {
					const {
						items
					} = res;
					if (res.state == 102) { //判断 店铺是否关闭
						this.$store.commit('setShowApp', false)
						return
					} else {
						this.$store.commit('setShowApp', true)
						this.$store.commit("SET_SHOP_INFO", items);
						// 通过code转化地址
						const shopAddr = items.list?.shopAddr || "";
						const detailAddr = items.list?.detailAddr;
						const shopAddrCode = shopAddr.split(",");
						codeToAddr(
							shopAddrCode[0],
							shopAddrCode[1],
							shopAddrCode[2],
							shopAddrCode[3]
						).then((res) => {
							const totalShopAddr = res + detailAddr;
							localStorage.setItem("totalShopAddr", totalShopAddr);
						});
					}

				});
			},
			async genPosterRQ(url) {
				let data = await this.getImgBase64(url);
				this.posterQR = data;
			},
			async genPosterLogo(url) {
				let data = await this.getImgBase64(url);
				this.posterLogo = data;
			},
			async genPosterContent(url) {
				let data = await this.getImgBase64(url);
				this.posterContent = data;
			},
			async getImgBase64(url) {
				let data = await new Promise(function(resolve, reject) {
					var canvas = document.createElement("canvas"),
						ctx = canvas.getContext("2d"),
						img = new Image();
					img.crossOrigin = "";
					img.onload = function() {
						canvas.height = img.height;
						canvas.width = img.width;
						ctx.drawImage(img, 0, 0);
						var dataURL = canvas.toDataURL("image/png");
						canvas = null;
						resolve(dataURL);
					};
					img.src = url;
				});
				return data;
			},
			getInfo(data) {
				this.info = {};
				this.pictureList = [];
				this.$router.push({
					name: "ProductDetails",
					query: data,
				});
			},
			errorImg(e) {
				e.target.src = this.headerImg;
			},
			// 浏览记录
			browsingHistory() {
				let param = {
					uuid: this.info.uuid,
				};
				browsingHistory(param).then((resp) => {
					this.historyList = resp.items;
				});
			},
			showDetail(item) {
				if (item?.filePath) {
					return true;
				} else {
					return false;
				}
			},
			async onClickIcon(type) {
				let _this = this;
				if (type == 1) {
					this.$router.push({
						name: "Home",
						query: {
							shopUuid: this.info.shopUuid
						}
					});
				}
				if (type == 3) {

					this.$nextTick(() => {
						var canvas = document.createElement("canvas"),
							ctx = canvas.getContext("2d"),
							img = new Image();
						img.crossOrigin = "";
						img.src = _this.pictureList[0]?.filePath;
						img.onload = function() {
							canvas.height = img.height;
							canvas.width = img.width;
							ctx.drawImage(img, 0, 0);
							var dataURL = canvas.toDataURL("image/png");
							canvas = null;
							_this.$refs.poster.show(_this.posterLogo, dataURL, _this.pictureList[0]
								?.filePath);
						};
					})

				}
				if (type == 4) {
					let userId = this.$store.state.userInfo ? this.$store.state.userInfo.uuid : ''
					let param = {
						num: 1,
						productId: this.info.uuid,
						shopUuid: this.info.shopUuid,
						userId: userId
					};
					addToCart(param).then((res) => {
						if (res.state == 100) {
							Toast.success("成功加入购物车");
						}
					});
				}
			},
			//申请授权
			apply() {
				this.$router.push('/profile')
			},
			imagePreview(index, images) {
				const pics = images.map((o) => o.filePath);
				ImagePreview({
					images: pics,
					startPosition: index,
				});
			},

			async getProOne() {
				let self = this;
				const data = {
					uuid: this.uuid,
				};
				if (this.$store.state.shopUuid) {
					await getShopOne()
				}
				await getProOne(data).then((res) => {
					if (res.state == 100) {
						this.info = res.items;
						let titles = this.info.prtName;
						const picList = this.info.picList || [];
						this.detailPic = picList.filter((item) => item.typeName == "prtInfo");
						this.videoPictureList = picList.filter(
							(o) => o.typeName == "videoPicture"
						);

						// prtPicture

						this.getFileList();
						this.browsingHistory();

						//   this.$route.meta.title = titles;

						this.$forceUpdate();
						this.title = titles;
						setPageTitle(titles);

						this.$store.commit("SET_PRODUCT_INFO", this.info);
						this.$store.commit("setProduct", 1);

						const params = {
							page: 0,
							pageSize: 0,
							typeName: "deWxOAQrCode",
							parentUuid: this.$store.state.shopUuid,
						};
						getFileList(params).then((res) => {
							if (res.state == 100) {
								this.wxQrCode = res.items[0]?.filePath;
							}
						});

					}
				});


			},

			// 获取列表数据
			async getFileList() {
				let self = this;
				const params = {
					page: 0,
					pageSize: 0,
					typeName: "prtPicture",
					parentUuid: this.info.uuid,
				};
				await getFileList(params).then((res) => {
					if (res.state == 100) {
						this.pictureList = res.items;
						wxConfig(1, {
							name: this.title,
							url: this.pictureList[0]?.filePath
						});
					}
				});
				this.posterContent = ''
				await self.genPosterLogo(self.$store.state.headUrl);
				await self.genPosterContent(self.pictureList[0]?.filePath);
				await self.genPosterRQ(self.qrCode);
			},
			handleFn(type, val) {

				if (type == 'isColl') {
					if (val == 1) {
						this.reCollection(type);
					} else {
						this.collection(type);
					}
				} else {
					this.collection(type);
				}

			},

			// 取消收藏喜欢
			reCollection(type) {
				const data = {
					likColl: type == "isColl" ? 0 : 1,
					prtUuid: this.info.uuid,
				};
				reCollection(data).then(() => {
					type == "isColl" ? (this.info.isColl = 0) : (this.info.isLike = 0);
					this.info.collCount = Number(this.info.collCount) - 1
				});
			},

			// 收藏喜欢
			collection(type) {
				const data = {
					state: type == "isColl" ? 0 : 1,
					slist: [this.info.uuid],
				};
				collections(data).then(() => {
					type == "isColl" ? (this.info.isColl = 1) : (this.info.isLike = 1);
					this.info.collCount = Number(this.info.collCount) + 1
				});
			},

			async goVideoPlay() {
				this.prtVideoList = await this.getVideoList();
				// 增加视频点击量
				clickPrtAddOne({
					uuid: this.info.uuid
				});

				const videoArr = this.prtVideoList.map((item, index) => {
					const oneVideo = {
						id: index,
						bgImg: this.info.isPic == 1 ?
							this.videoPictureList[0]?.filePath ?? "" : "",
						isLoad: index == 0,
						sources: item.filePath || "",
						infoArr: this.getVideoInfo,
						uuid: this.info.uuid,
						isLike: this.info.isLike,
						prtVideoUrl: this.info?.prtVideoUrl
					};
					return oneVideo;
				});
				localStorage.setItem("videoArr", JSON.stringify(videoArr));
				this.$router.push({
					name: "VideoPlay",
					query: {
						uuid: this.info.uuid,
						type: "noClose",
						title: this.info.prtName,
						names: this.info.prtName,
						shopUuid: this.$store.state.shopUuid,
					},
				});
			},

			// 获取视频
			getVideoList() {
				const params = {
					page: 0,
					pageSize: 0,
					typeName: "prtVideo",
					parentUuid: this.info.uuid,
				};
				return getFileList(params).then((res) => {
					if (res.state == 100) {
						return res.items;
					}
				});
			},

			goPicture() {
				this.$router.push({
					name: "ProPicture",
					query: {
						uuid: this.info.uuid
					},
				});
			},
		},
	};
</script>

<style lang="less" scoped>
	.picture-btn {
		border-radius: 15px;
		margin-right: 14px;
		background-size: 100% 100%;
	}

	.footer {
		position: fixed;
		bottom: 0;
		width: 100%;
		height: 60px;
		z-index: 999;
		background: #ffffff;
		box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.1);
		display: flex;
		justify-content: space-between;
		padding-left: 20px;

		.text-center {
			text-align: center;
			padding-top: 5px;
			font-size: 10px;
			color: #323232;
		}

		img {
			height: 19px;
			width: 19px;
			margin-top: 12px;
		}

		.flex-center {
			display: flex;
			justify-content: center;
			align-items: center;

			.btn {
				width: 174px;
				height: 39px;
				background: linear-gradient(270deg, #00D2FF 0%, #00d8ff 100%);
				border-radius: 20px;
				// line-height: 39px;
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;
				font-size: 15px;
				color: #ffffff;
				font-weight: 400;
				margin-right: 20px;
			}
		}
	}

	.browseModal {
		width: 100%;
		height: 100%;
		background-color: #ffffff;
		z-index: 9999;
		border-radius: 10px 0;
		position: relative;

		.header {
			height: 50px;
			background-color: #cccccc;
			line-height: 50px;
			border-radius: 10px 10px 0 0;
			text-align: center;
		}

		.content {
			height: 80%;
			padding-bottom: 50px;

			.list {
				height: 40px;
				line-height: 40px;
				display: flex;
				justify-content: space-between;
				padding: 0 20px 0 15px;

				img {
					border-radius: 50%;
					margin-top: 5px;
					height: 30px;
					/*no*/
					width: 30px;
					/*no*/
				}

				span {
					padding-left: 10px;
				}
			}
		}

		.foot {
			height: 50px;
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			line-height: 50px;
			color: #ffffff;
			text-align: center;
			background-color: #c800fe;
		}
	}

	.van-goods-action {
		height: 60px;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
		z-index: 99;

		.van-goods-action-button--danger {
			background: linear-gradient(270deg, #00a8ff 0%, #00d8ff 100%);
			width: 174px;
			height: 39px;
		}
	}

	.swipe-container {
		width: 100%;
		height: 350px;

		.my-swipe {
			.van-swipe-item {
				display: flex;
				justify-content: center;
				align-items: center;

				.swipe-item-box {
					width: 100%;
					height: 350px;
					background-size: 100% 100%;

					&>img {
						width: 100%;
						height: 100%;
					}
				}
			}

			.custom-indicator {
				position: absolute;
				width: 100%;
				text-align: center;
				bottom: 11px;
				pointer-events: none;
				display: flex;
				justify-content: center;
				align-items: center;

				.indicator {
					overflow: hidden;
					margin: 0 4px;
					width: 7px;
					height: 7px;
					background: #e5e5e5;
					opacity: 0.5;
					border-radius: 50%;
					transition: 0.2s all;
				}

				.indicator.active {
					width: 50px;
					height: 7px;
					opacity: 1;
					background: #fff;
					border-radius: 7px;
				}
			}
		}
	}

	.product-detail {
		padding-top: 78px;
		padding-bottom: 70px;
		background: #ffffff;

		.pro-title {
			display: flex;
			padding-top: 15px;
			background: #ffffff;
			padding-right: 20px;

			.left-line {
				width: 9px;
				height: 22px;
				background-color: var(--theme-color);
			}
		}

		.title {
			width: 100%;
			font-size: 20px;
			font-family: Microsoft YaHei;
			color: #323232;
			padding-left: 20px;
			background: #ffffff;
		}

		.flex-center {
			display: flex;
			justify-content: center;
			align-items: center;
			background: #ffffff;
		}

		.footer-like-list {
			background: #ffffff;
			padding-right: 20px;
			display: flex;
			justify-content: flex-end;
			padding-top: 15px;
			padding-bottom: 30px;

			img {
				height: 18px;
				width: 18px;
			}

			.num {
				height: 18px;
				display: flex;
				align-items: center;
				text-align: center;
				padding-left: 5px;
				font-size: 13px;
				color: #323232;
			}
		}

		.qy-qrcode {
			display: flex;
			background: #ffffff;
			padding: 20px;
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

			img {
				height: 88px;
				width: 88px;
			}

			.qrcode-title {
				font-size: 15px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #323232;
			}

			.qrcode-mark {
				font-size: 13px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #969696;
				padding-top: 14px;
			}
		}

		&::before {
			content: "";
			display: table;
		}

		.top-nav-bar {
			position: fixed;
			top: 0;
			width: 100%;
			height: 60px;
			z-index: 999;
			box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.15);
		}

		.pro-detail-top {
			// background: rgba(234, 234, 234, 0.2);
			background: #ffffff;
			width: 350px;
			position: relative;

			.right-btn {
				display: flex;
				border-radius: 50px 0 0 50px;
				font-size: 13px;
				color: #ffffff;
				height: 28px;
				width: 96px;
				line-height: 28px;
				position: absolute;
				right: 0;
				top: 100px;
				background-color: rgba(0, 0, 0, 0.2);

				img {
					border-radius: 50px;
					/*no*/
					margin-top: 3px;
					margin-left: 5px;
				}
			}

			&::before {
				content: "";
				display: table;
			}

			.video-play-icon {
				position: absolute;
				top: calc(50% - 34px);
				left: calc(50% - 24px);
				width: 53px;
				height: 53px;
			}


		}

		.pro-detail-body {
			width: 100%;
			padding-left: 30px;
			padding-top: 16px;
			display: flex;
			flex-direction: column;
			background: #ffffff;

			.price {
				font-size: 15px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #ff1448;
				margin: 14px 0 18px;
			}

			.content {
				display: flex;
				align-items: center;
				margin-bottom: 9px;

				.icon {
					width: 6px;
					/*no*/
					height: 6px;
					/*no*/
					background: #3671ff;
					border-radius: 50%;
					margin-top: -2px;
				}

				&>span {
					font-size: 13px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #323232;
					margin-left: 8px;
				}
			}
		}

		.body-footer {
			background-color: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 23px 0;

			&>div {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				&>img {
					width: 20px;
					height: 20px;
					margin-bottom: 9px;
				}

				&>span {
					font-size: 13px;
					font-family: Microsoft YaHei;
					font-weight: 500;
					color: #ffbc00;
					line-height: 14px;
				}
			}

			.xi-huan {
				margin-left: 57px;

				span {
					color: #ff1448 !important;
				}
			}
		}

		.detail-pictures {
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

			.title {
				background: #ffffff;
				width: 100%;
				height: 60px;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				margin-bottom: 7px;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #323232;

				&>img {
					width: 20px;
					height: 20px;
					margin: 0 8px;
					display: inline-block;
				}

				span {
					height: 60px;
					line-height: 60px;
				}
			}

			.details-pic {
				background: #fff;
				/*padding: 7px 7px;*/
				margin: 7.5px 0 7.5px 0;
			}
		}
	}
</style>