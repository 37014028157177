<template>
  <div class="recommend-container" >
    <div class="title">
      <img :src="detailIcon.heart" alt="" />
      <span>猜你喜欢</span> <span style="font-size: 13px;color: #c8c8c8;font-weight: 400;">/CAINIXIHUAN</span>
    </div>
	<div  class="product-list">
	   <ProductCard  v-for="item in proList" :key="item.uuid" @getInfo="getInfo"  :info="item" />
	 </div>
  </div>
</template>

<script>
import { getYouLike } from "@/api";
// import ProductList from "@c/common/ProductList.vue";
import ProductCard from "@c/common/ProductCard.vue";
import b_heart from '@/assets/img-v3/new-blue-detail/heart.png'
import r_heart from '@/assets/img-v3/new-red-detail/heart.png'
export default {
  name: "RecommendList",
  components: {
    ProductCard
  },
  data() {
    return {
      proList: [],
	  detailIcon:{
		  heart:b_heart
	  }
    };
  },

  created() {
    this.getYouLike();
  },

  methods: {
    productDetailChange() {
       this.$emit("ProductDetailChange1", 1);
    },
	getInfo(data) {
	  this.$emit('getInfos',data)
	},
    getYouLike() {
      const params = {
        shopUuid: this.$store.state.shopUuid,
        uuid: this.$route.query.uuid,
      };
      getYouLike(params).then((res) => {
        if (res.state == 100) {
          this.proList = res.items;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.recommend-container {
  margin-top: 14px;
  
.product-list{
	  display: flex;
	  justify-content: space-between;
	  flex-wrap: wrap;
	  margin: 10px;
	  // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .title {
    background: #ffffff;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 7px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #323232;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    & > img {
      width: 20px;
      height: 20px;
      margin: 0 8px 0 28px;
      display: inline-block;
    }
    span {
      height: 60px;
      line-height: 60px;
    }
  }
}
</style>
