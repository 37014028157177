<template>
	<div class="swipe-container">
		<van-swipe class="my-swipe" :show-indicators="false"  indicator-color="white" @change="onChange">
			<van-swipe-item v-for="item in swipeList" :key="item.uuid">
				<div class="swipe-item-box"><img :src="item.filePath" alt="" /></div>
			</van-swipe-item>
		</van-swipe>
	</div>
</template>

<script>
import { getFileList } from '@/api';
export default {
	name: 'Swipe',
	props: {
		typeName: {},
		parentUuid:{}
	},
	data() {
		return {
			swipeList: [],
			active: 0
		};
	},

	created() {
		this.getFileList();
	},
	methods: {
		// 获取列表数据
		getFileList() {
			const params = {
				page: 0,
				pageSize: 0,
				typeName: this.typeName,
				parentUuid: this.parentUuid
			};
			getFileList(params).then(res => {
				if (res.state == 100) {
					this.swipeList = res.items;
				}
			});
		},

		onChange(index) {
			this.active = index;
			this.$emit('onChange',index)
		}
	}
};
</script>

<style lang="less" scoped>
.van-swipe {
}
.swipe-container {
	width: 100%;
	height: 350px;
	.my-swipe {
		.van-swipe-item {
			display: flex;
			justify-content: center;
			align-items: center;
			.swipe-item-box {
				width: 100%;
				height: 350px;
				& > img {
					width: 100%;
					height: 100%;
				}
			}
		}

		.custom-indicator {
			position: absolute;
			width: 100%;
			text-align: center;
			bottom: 11px;
			pointer-events: none;
			display: flex;
			justify-content: center;
			align-items: center;

			.indicator {
				overflow: hidden;
				margin: 0 4px;
				width: 7px;
				height: 7px;
				background: #e5e5e5;
				opacity: 0.5;
				border-radius: 50%;
				transition: 0.2s all;
			}

			.indicator.active {
				width: 50px;
				height: 7px;
				opacity: 1;
				background: #fff;
				border-radius: 7px;
			}
		}
	}
}
</style>
